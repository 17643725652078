<template>
  <modal
    :width="1300"
    :adaptive="true"
    class="des-img-popup CEModal modal-scroll-bar"
    name="destinationImgModal"
  >
    <button class="button modal-changeHotel__close" @click="$emit('closePopup')">
      <img src="/assets/img/close.svg" alt />
    </button>
    <div class="preview-block">
      <VueSlickCarousel
        ref="p1"
        :asNavFor="$refs.p2"
        :focusOnSelect="true"
        :arrows="false"
        @beforeChange="onBeforeChangeP2"
      >
        <div v-for="(hotelImage, index) in desImage" :key="'p1' + index" class="preview-carousel-item">
          <h3 class="text-center mb-5">{{ hotelImage.ImageTitle }}</h3>
          <img
            :src="hotelImage.Url"
            alt
          />
        </div>
      </VueSlickCarousel>
    </div>
    <div class="thumb-block">
      <VueSlickCarousel
        ref="p2"
        :asNavFor="$refs.p1"
        :slidesToShow="5"
        :focusOnSelect="true"
        @beforeChange="onBeforeChangeP1"
      >
        <div v-for="(hotelImage, index) in desImage" :key="'p2' + index" class="thumb-carousel-item">
          <button class="remove_btn mb-5">
            <img
              src="/new-assets/icon/trash-red.png"
              alt=""
              class="pointer"
            >
          </button>
          <img
            :src="hotelImage.Url"
            alt
          />
          <h5 class="text-center mt-5">
            {{ hotelImage.ImageTitle }}
          </h5>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="d-flex bottom-action mb-5">
      <div class="d-flex">
        <button class="primary-btn bg-red6" @click="$emit('closePopup')">
          {{ $t('Close') }}
        </button>
        <button class="primary-btn">
          {{ $t('Save') }}
        </button>
      </div>
      <div class="image-upload">
        <p class="fs16 m-0">{{ $t('Upload images') }}</p>
        <ImgUpload v-model="image" /> 
      </div>
    </div>
  </modal>
</template>

<script>
import ImgUpload from "./ImgUpload"
export default {
  name: "DestinationImgModal",
  components: {
    ImgUpload
  },
  data() {
    return {
      desImage: [
        {
          ImageTitle:"Hellinis",
          ImageType:"MAIN",
          Url:"https://www.amadeus.co.il/hotelImage/31491.jpg"
        },
        {
          ImageTitle:"Dining",
          ImageType:"HOTEL",
          Url:"https://www.amadeus.co.il/hotelImage/dining_31491.jpg"

        },
        {
          ImageTitle:"Room1",
          ImageType:"HOTEL",
          Url:"https://www.amadeus.co.il/hotelImage/room1_31491.jpg"
        },
        {
          ImageTitle:"Room2",
          ImageType:"HOTEL",
          Url:"https://www.amadeus.co.il/hotelImage/room2_31491.jpg"
        },
        {
          ImageTitle:"Room2",
          ImageType:"HOTEL",
          Url:"https://www.amadeus.co.il/hotelImage/room2_31491.jpg"
        },
        {
          ImageTitle:"Room2",
          ImageType:"HOTEL",
          Url:"https://www.amadeus.co.il/hotelImage/room2_31491.jpg"
        }
      ],
      image: ''
    }
  },
  methods: {
    /* update slick carousel slider sync issue */
    onBeforeChangeP1(currentPosition, nextPosition) {
      this.$refs.p1.goTo(nextPosition)
    },
    onBeforeChangeP2(currentPosition, nextPosition) {
      this.$refs.p2.goTo(nextPosition)
    },
  }
}
</script>

<style lang="scss">
 /* img popup */
  .des-img-popup {
    .preview-block *,
    .thumb-block * {
      direction: ltr !important;
    }
    .slick-prev::before, .slick-next::before {
      color: gray;
    }
    .vm--modal {
      padding: 30px 0;
      max-height: unset !important;
    }
    .modal-changeHotel__close {
      position: absolute;
      top: 10px;
      left: 15px;
      cursor: pointer;
      img {
        width: 15px;
      }
    }
    .preview-block {
      padding: 10px 80px;
      .preview-carousel-item {
        img {
          height: 500px;
          width: 100%;
          object-fit: contain;
        }
      }
    }
    
    .thumb-block {
      padding: 20px 60px;
      .thumb-carousel-item {
        padding: 0 7px;
        position: relative;
        img {
          height: 100px;
          width: 100%;
        }
        .remove_btn {
          position: absolute;
          right: 7px;
          top: 0;
          background: #c90000;
          width: 19px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 17px;
            height: auto;
          }
        }
      }
      .slick-prev, .slick-next {
        height: calc(100% - 30px);
        top: 49px;
        width: 25px;
        background: #EEEEEE;
        border: 1px solid #707070;
        &::before {
          content: '';
          background:url('/assets/img/chevron.svg');
          display: block;
          width: 14px;
          height: 24px;
          background-size: contain;
          top: 35%;
          position: absolute;
          left: 5px;
        }
      }
      .slick-next::before {
        transform: rotate(180deg);
      }
    }
    .bottom-action {
      padding: 0 50px;
      align-items: flex-end;
      .primary-btn {
        padding: 0 20px;
        margin: 0 5px;
        font-size: 16px;
        height: 36px;
      }
      .image-upload {
        margin: 0 50px;
        .form-item {
          height: 60px !important;
          width: 100px;
          .upload-icon {
            height: 80%;
          }
        }
      }
    }
  }
</style>