<template>
  <main class="destination page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="des-title">{{ $t("Management of tourist destinations") }}</p>
    <div class="table-content-part">
      <div class="search-bar">
        <p class="search-label">{{ $t("Search") }}</p>
        <v-text-field
          v-model="search"
          single-line
          hide-details
          class="search-form"
        ></v-text-field>
      </div>
      <v-data-table :headers="headers" :items="destination_data" class="cus-table" :search="search">
        <template v-slot:[`item.active`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox v-model="item.active" color="#0D3856" class="cus-checkbox"></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.view`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox v-model="item.view" color="#0D3856" class="cus-checkbox"></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.flight`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox v-model="item.flight" color="#0D3856" class="cus-checkbox"></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.nofhson`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox v-model="item.nofhson" color="#0D3856" class="cus-checkbox"></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.organzied_tour`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox v-model="item.organzied_tour" color="#0D3856" class="cus-checkbox"></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.save_active`]="{ item }">
          <a class="link category__button row-btn" @click="saveOneDes(item.id)">{{ $t("Save") }}</a>
        </template>
        <template v-slot:[`item.edit_active`]="{ }">
          <v-icon medium color="dark">edit</v-icon>
        </template>
        <template v-slot:[`item.video_links`]="{ item }">
          <v-icon medium color="dark" class="pointer" @click="openVideoLinkModal(item.id)">video_library</v-icon>
        </template>
        <template v-slot:[`item.edit_destination`]="{ item }">
          <div class="edit-des_btn pointer" @click="openDescriptionModal(item.id)">
            !
          </div>
        </template>
        <template v-slot:[`item.image`]="{ }">
          <div class="d-flex justify-content-center pointer" @click="openImgPopup()">
            <img src="/assets/img/icons/img-icon.png" alt="">
          </div>
        </template>
        <template v-slot:[`item.visa`]="{ item }">
          <span v-if="item.visa == true">{{ $t("Yes") }}</span>
          <span v-else>{{ $t("No") }}</span>
        </template>
        <template v-slot:[`item.tags`]="{ item }">
          <div 
            class="cus-multi_dropdownbtn js-cus-dropdownbtn" 
            @click="show_dropdown(item.id, 'tag')" 
            :id="'tag'+item.id"

          >
            <span v-for="(tag, index) in item.newTags" :key="index">
              <span v-if="index == 0">
                {{tag.name}}
              </span>
              <span v-if="index != 0">
                , {{tag.name}}
              </span>
            </span>
          </div>
          <div class="dropdown-content js-tag" :ref="'tag'+item.id">
            <input type="text" class="input search-tag" v-model="tag_search" @keyup="search_tag">
            <ul class="pl-0">
              <li v-for="opt in filteredList" :key="opt.id" class="d-flex justify-content-between align-items-center tag-opt">
                <p class="m-0">{{opt.name}}</p>
                <v-checkbox v-model="item.tags" color="#0D3856" class="cus-checkbox" :value="opt.id" @change="changeTags(item.id, item.tags)"></v-checkbox>
              </li>
            </ul>
          </div> 
        </template>
        <template v-slot:[`item.airports`]="{ item }">
          <div 
            class="cus-multi_dropdownbtn js-cus-dropdownbtn" 
            @click="show_dropdown(item.id, 'airport')" 
            :id="'airport'+item.id"
          >
            <span v-for="(airport, index) in item.airports" :key="index">
              <span v-if="airport.active && index == 0">
                {{airport.code}}
              </span>
              <span v-if="airport.active && index != 0">
                , {{airport.code}}
              </span>
            </span>
          </div>
          <div class="dropdown-content airport-content js-airport" :ref="'airport'+item.id">
            <div class="airport-title">
              <div class="d-flex align-items-center">
                <img src="/assets/img/berlin.png" alt="">
                <p> {{item.name}},  {{ $t("All airports") }} </p>
              </div> 
              <a class="add-form-btn" @click="addAirport(item.id)">{{ $t("Add") }}</a>
            </div>
            <div class="each-airport add-form" :ref="'addAirportForm'+item.id">
              <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-between align-items-center">
                  <img src="/assets/img/enter-symbol.png" alt="" class="enter-symbol" >
                  <img src="/assets/img/plane-sm.png" alt="" class="plane-sm">
                  <div>
                    <input type="text" class="input input_name" v-model="addAirportForm.name">
                    <p class="airport-des">{{item.name}}, {{item.country}}</p>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <input type="text" class="input input_code" v-model="addAirportForm.code">
                  <v-checkbox v-model="addAirportForm.active" color="#0D3856" class="cus-checkbox"></v-checkbox>
                </div>
              </div>
              <a @click="add_airport(item.id)">{{ $t("Confirm") }}</a>
            </div>
            <ul class="pl-0">
              <li v-for="opt in item.airports" :key="opt.id" class="each-airport">
                <div class="d-flex justify-content-between align-items-center">
                  <img src="/assets/img/enter-symbol.png" alt="" class="enter-symbol">
                  <img src="/assets/img/plane-sm.png" alt="" class="plane-sm">
                  <div>
                    <p class="airport-name">{{opt.name}}</p>
                    <p class="airport-des">{{item.name}}, {{item.country}}</p>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <p class="airport-code">{{opt.code}}</p>
                  <v-checkbox v-model="opt.active" color="#0D3856" class="cus-checkbox"></v-checkbox>
                </div>
              </li>
            </ul>
          </div>
        </template>
        <template v-slot:[`item.country`]="{ item }">
          <div 
            class="cus-multi_dropdownbtn js-cus-dropdownbtn" 
            @click="show_dropdown(item.id, 'country')" 
            :id="'country'+item.id"

          >
            <span>
              {{item.country_he}}
            </span>
          </div>
          <div class="dropdown-content js-tag" :ref="'country'+item.id">
            <div class="td-input mt-2">
              <label for="">HE</label>
              <input type="text" class="input" v-model="item.country_he">
            </div>
            <div class="td-input">
              <label for="">EN</label>
              <input type="text" class="input" v-model="item.country_en">
            </div>
            <div class="td-input mb-2">
              <label for="">AR</label>
              <input type="text" class="input" v-model="item.country_ar">
            </div>
          </div> 
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <div 
            class="cus-multi_dropdownbtn js-cus-dropdownbtn" 
            @click="show_dropdown(item.id, 'name')" 
            :id="'name'+item.id"

          >
            <span>
              {{item.name_he}}
            </span>
          </div>
          <div class="dropdown-content js-tag" :ref="'name'+item.id">
            <div class="td-input mt-2">
              <label for="">HE</label>
              <input type="text" class="input" v-model="item.name_he">
            </div>
            <div class="td-input">
              <label for="">EN</label>
              <input type="text" class="input" v-model="item.name_en">
            </div>
            <div class="td-input mb-2">
              <label for="">AR</label>
              <input type="text" class="input" v-model="item.name_ar">
            </div>
          </div> 
        </template>
      </v-data-table>
    </div>
    <a class="link category__button new-btn" @click="openCreatModal()">{{ $t("New") }}</a>
    <a class="link category__button total-save-btn" @click="saveAllDes()">{{ $t("Save") }}</a>

    <modal name="createModal" class="destination-create-modal CEModal modal-scroll-bar">
      <div class="destination-modal-content">
        <h2>Add destination</h2>
        <div class="row">
          <div class="col-sm-4">Tags</div>
          <div class="col-sm-8">
            <p class="input-exp">Please input like this: 2,3,4</p>
            <input type="text" class="input" v-model="createForm.tags" />
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-sm-4">Airports</div>
          </div>
          <div class="row">
            <div class="col-sm-4 sub-form-label">Name</div>
            <div class="col-sm-8">
              <input type="text" class="input" v-model="createForm.airports[0].name" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 sub-form-label">Code</div>
            <div class="col-sm-8">
              <input type="text" class="input" v-model="createForm.airports[0].code" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 sub-form-label">Active</div>
            <div class="col-sm-8">
              <div style="display: flex;">
                <v-checkbox color="#0D3856" class="cus-checkbox" v-model="createForm.airports[0].active" ></v-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">Sort Order</div>
          <div class="col-sm-8">
            <input type="radio" id="sort_order_123" name="sort_order" value="123"  v-model="createForm.sort_order" />
            <label for="sort_order_123" class="px-5">123</label>
            <div style="display: inline-block; width:40px;"></div>
            <input type="radio" id="sort_order_321" name="sort_order" value="321" v-model="createForm.sort_order" />
            <label for="sort_order_321" class="px-5">321</label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">Visa</div>
          <div class="col-sm-8">
            <div style="display: flex;">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="createForm.visa"></v-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">Active</div>
          <div class="col-sm-8">
            <div style="display: flex;">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="createForm.active"></v-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">View</div>
          <div class="col-sm-8">
            <div style="display: flex;">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="createForm.view"></v-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">Flight</div>
          <div class="col-sm-8">
            <div style="display: flex;">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="createForm.flight"></v-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">Nofhson</div>
          <div class="col-sm-8">
            <div style="display: flex;">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="createForm.nofhson"></v-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">Organized Tour</div>
          <div class="col-sm-8">
            <div style="display: flex;">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="createForm.organzied_tour"></v-checkbox>
            </div>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-sm-4">Country</div>
          </div>
          <div class="row">
            <div class="col-sm-4 sub-form-label">Englsih</div>
            <div class="col-sm-8">
              <input type="text" class="input" v-model="createForm.country_en" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 sub-form-label">Hebrew</div>
            <div class="col-sm-8">
              <input type="text" class="input" v-model="createForm.country_he" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 sub-form-label">Arabic</div>
            <div class="col-sm-8">
              <input type="text" class="input" v-model="createForm.country_ar" />
            </div>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-sm-4">Destination Name</div>
          </div>
          <div class="row">
            <div class="col-sm-4 sub-form-label">Englsih</div>
            <div class="col-sm-8">
              <input type="text" class="input" v-model="createForm.name_en" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 sub-form-label">Hebrew</div>
            <div class="col-sm-8">
              <input type="text" class="input" v-model="createForm.name_he" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 sub-form-label">Arabic</div>
            <div class="col-sm-8">
              <input type="text" class="input" v-model="createForm.name_ar" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a class="link category__button cancel-btn" @click="closeCreateModal()">{{ $t("Cancel") }}</a>
          <a class="link category__button create-btn" @click="addDestination()">{{ $t("Ok") }}</a>
        </div>
      </div>
    </modal>
    <modal name="descriptionModal" class="destination-create-modal destination-desc-modal CEModal modal-scroll-bar">
      <div class="destination-modal-content">
        <h2>{{ $t("Destination Description") }}</h2>
        <vue-editor v-model="description" class="bg-white mb-10"></vue-editor>
        <div class="modal-footer">
          <a class="link category__button cancel-btn" @click="closeDescriptionModal()">{{ $t("Cancel") }}</a>
          <a class="link category__button create-btn" @click="closeDescriptionModal()">{{ $t("Ok") }}</a>
        </div>
      </div>
    </modal>
    <modal name="videoLinkModal" class="destination-create-modal destination-desc-modal CEModal modal-scroll-bar">
      <div class="destination-modal-content">
        <h2>{{ $t("Video links") }}</h2>
        <button class="primary-btn add-video_btn" @click="addOneVideo()">{{ $t("Add") }}</button>
        <div v-for="(video_link, index) in video_links" :key="index" class="mt-5">
          <label for="">{{ $t("Video") }} {{index + 1}}</label>
          <div class="d-flex justify-content-between align-items-center">
            <input type="text" v-model="video_link.value" class="input">
            <v-icon 
              medium color="dark" 
              @click="removeOneVideo(index)" 
              class="mx-2">
                clear
            </v-icon>
          </div>
        </div>
        
        <div class="modal-footer">
          <a class="link category__button cancel-btn" @click="closeVideoLinkModal()">{{ $t("Cancel") }}</a>
          <a class="link category__button create-btn" @click="updateVideoLink()">{{ $t("Ok") }}</a>
        </div>
      </div>
    </modal>

    <!-- ------------destination img popup-------------- -->
    <DestinationImgModal @closePopup="closeImgPopup()"></DestinationImgModal>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import DestinationImgModal from '../../../components/DestinationImgModal'

export default {
  name: "Destination",
  components: {
    Loading,
    DestinationImgModal 
  },
  data() {
    return {
      search: "",
      tag_search: "",
      visible: false,
      headers: [
        {
          text: this.$t("Country"),
          align: "center",
          sortable: false,
          value: "country"
        },
        {
          text: this.$t("Destination name"),
          align: "center",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("Airports"),
          align: "center",
          sortable: false,
          value: "airports"
        },
        {
          text: this.$t("Tags"),
          align: "center",
          sortable: false,
          value: "tags"
        },
        {
          text: this.$t("Order"),
          align: "center",
          sortable: false,
          value: "sort_order"
        },
        {
          text: this.$t("Visa"),
          align: "center",
          sortable: false,
          value: "visa"
        },
        {
          text: this.$t("Active"),
          align: "center",
          sortable: false,
          value: "active"
        },
        {
          text: this.$t("Display"),
          align: "center",
          sortable: false,
          value: "view"
        },
        {
          text: this.$t("Flights"),
          align: "center",
          sortable: false,
          value: "flight"
        },
        {
          text: this.$t("Vacationers"),
          align: "center",
          sortable: false,
          value: "nofhson"
        },
        {
          text: this.$t("Organized"),
          align: "center",
          sortable: false,
          value: "organzied_tour"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "image"
        },
        {
          text: this.$t("Video links"),
          align: "center",
          sortable: false,
          value: "video_links"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "edit_destination"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "edit_active"
        },
        {
        text: "",
        align: "center",
        sortable: false,
        value: "save_active"
        }
      ],
      selectedTag: "",
      oldAirId: "",
      id_showed: "",
      filteredList: [],
      addAirportForm: {
        name: "",
        code: "",
        active: true
      },
      createForm: {
          tags: [],
          airports: [{
            name: "",
            code: "",
            active: false
          }],
          sort_order:"123",
          visa: false,
          active: false,
          view: false,
          flight: false,
          nofhson: false,
          organzied_tour: false,
          name_en: "",
          country_en: "",
          name_he: "",
          country_he: "",
          name_ar: "",
          country_ar: ""
      },
      video_id: "",
      video_links: [],
      description: ""
    };
  },
  computed: {
    ...mapState({
      loading: state => state.destination.loading,
      destination_data: state => state.destination.destinationData_receipts,
      tags_data: state => state.destination.tagsData_receipts
      
    }),
    
  },
  async created() {
    if (this.destination_data == "") {
      await this.getDestination();
    }

    if (this.tags_data == "") {
      await this.getTags();
    }

    this.destination_data.forEach(item => {
      item.newTags = [];
      item.tags.forEach(itemTag => {
        this.tags_data.forEach(tag => {
          if(tag.id == itemTag) {
            item.newTags.push({id: tag.id, name: tag.name});
          }
        })
      })
    });
    this.filteredList = this.tags_data;
  },
  mounted () {
    document.addEventListener('click', this.close);
  },
  beforeDestroy () {
    document.removeEventListener('click',this.close);
  },
  methods: {
    ...mapActions("destination", {
      getDestination: "getDestination",
      getTags: "getTags",
      changeTagsState: "changeTagsState",
      save_OneDes: "save_OneDes",
      save_AllDes: "save_AllDes",
      addAirportstore: "addAirportstore",
      add_destination: "addDestination"
    }),

    /* multi-select dropdown show and close */
    show_dropdown(id, type) {
      id = type+id; 
      if (this.oldAirId == id) {
        if (this.$refs[id].style.display == "none") {
          this.$refs[id].style.display = "block";
          this.$refs[id].style.top = document.getElementById(id).offsetTop + 10 + "px";
          this.$refs[id].style.left = document.getElementById(id).offsetLeft + 40 + "px";
          this.id_showed = id;
          document.getElementById(id).classList.add("dropdownbtn_active");
        }
        else {
          this.$refs[id].style.display = "none"; 
          this.id_showed = ""; 
          document.getElementById(id).classList.remove("dropdownbtn_active");
          this.reset_tagSearch();
          this.reset_addAirportForm();
        }
         
      }
      else {
        if (this.oldAirId != "") {
          this.$refs[this.oldAirId].style.display = "none";
          document.getElementById(this.oldAirId).classList.remove("dropdownbtn_active");
          this.reset_tagSearch();
          this.reset_addAirportForm();
          
        }
        
        this.$refs[id].style.display = "block";
        this.$refs[id].style.top = document.getElementById(id).offsetTop + 10 + "px";
        this.$refs[id].style.left = document.getElementById(id).offsetLeft + 40 + "px";
        document.getElementById(id).classList.add("dropdownbtn_active");
        this.id_showed = id;
        this.oldAirId = id;
      }
    },

    close (e) {
      if(this.id_showed && this.id_showed != "") {
        const id = this.id_showed;

        if (!this.$refs[id].contains(e.target) && !e.target.classList.contains("js-cus-dropdownbtn")) {
          document.getElementById(id).classList.remove("dropdownbtn_active");
          this.$refs[id].style.display = "none"; 
          this.id_showed = ""; 
          this.reset_tagSearch();
          this.reset_addAirportForm();
        }
      }
    },
    /*  */


    /* save one destination */
    saveOneDes(id) {
      this.save_OneDes({id:id});
    },
    /*  */

    /* save all destination */
    saveAllDes(id) {
      this.save_AllDes();
    },
    /*  */
     
    /* change tags  */
    changeTags(id, newIds) {
      this.changeTagsState({id: id, tag_data: newIds});
      this.destination_data.forEach(item => {
        if(item.id == id) {
          item.newTags = [];
          newIds.forEach(id => {
            this.tags_data.forEach(tag => {
              if(tag.id == id) {
                item.newTags.push({id: tag.id, name: tag.name});
              }
            })
          })
        }
      });
    },
    /*  */

    /* add airport to destination */
    addAirport(id) {
      if(this.$refs["addAirportForm"+id].style.display != "block") {
        this.$refs["addAirportForm"+id].style.display = "block";
      }
    },
    add_airport(id) {
      if(this.addAirportForm.name == "" || this.addAirportForm.code == "" ) {
        return false;
      }
      let new_airport = {
        name: this.addAirportForm.name,
        code: this.addAirportForm.code.toUpperCase(),
        active: this.addAirportForm.active
      }
      this.addAirportstore({id: id, airport_data: new_airport});
      this.reset_addAirportForm();
      this.$refs["addAirportForm"+id].style.display = "none";
    },
    /*  */

    /*  add new destination */
    openCreatModal() {
      this.$modal.show("createModal");
    },
    async addDestination() {
      let temp = Object.assign({}, this.createForm);
      let tag_array = JSON.parse("[" + temp.tags + "]");
      temp.tags = tag_array;
      await this.add_destination({ destination_data: temp });
      
      this.closeCreateModal(); 
      this.reset_addCreateForm();
    },
    closeCreateModal() {
      this.$modal.hide("createModal");
    },
    
    /* search tags and reset search */
    search_tag() {
      let filteredList = this.tags_data.filter(item => {
        return item.name.toLowerCase().includes(this.tag_search.toLowerCase());
      });

      this.filteredList = filteredList;
    },

    reset_tagSearch() {
      this.tag_search = "";
      this.filteredList = this.tags_data;
    },

    reset_addAirportForm() {
      this.addAirportForm.name = "";
      this.addAirportForm.code = "";
      this.addAirportForm.active = true;
    },
    reset_addCreateForm() {
      this.createForm.tags = [];
      this.createForm.airports.name = "";
      this.createForm.airports.code = "";
      this.createForm.airports.active = "";
      this.createForm.sort_order = "123";
      this.createForm.visa = false;
      this.createForm.active = false;
      this.createForm.view = false;
      this.createForm.flight = false;
      this.createForm.nofhson = false;
      this.createForm.organzied_tour = false;
      this.createForm.name_en = "";
      this.createForm.name_he = "";
      this.createForm.name_ar = "";
      this.createForm.country_en = "";
      this.createForm.country_he = "";
      this.createForm.country_ar = "";
    },
    /*  */

    /* destination modal */
    openDescriptionModal(id) {
      this.$modal.show("descriptionModal");
    },
    closeDescriptionModal() {
      this.$modal.hide("descriptionModal");
    },

    /* video link modal */
    openVideoLinkModal(id) {
      this.video_id = id;
      this.video_links = [];
      const destinationData = this.destination_data.find(item => {
        return item.id == id;
      });
      if(destinationData.video_links) {
        for(let i=0; i<destinationData.video_links.length; i++) {
          this.video_links.push({
            value: destinationData.video_links[i]
          });
        }
      }
      
      this.$modal.show("videoLinkModal");
    },
    addOneVideo () {
      this.video_links.push({
        value: ""
      });
    },

    removeOneVideo (index) {
      this.video_links.splice(index, 1)
    },
    updateVideoLink() {
      const destinationData = this.destination_data.find(item => {
        return item.id == this.video_id;
      });
      destinationData.video_links = [];
      this.video_links.forEach(item => {
        destinationData.video_links.push(item.value);
      })
      
      this.save_OneDes({id:this.video_id});
      this.$modal.hide("videoLinkModal");
    },
    closeVideoLinkModal() {
      this.$modal.hide("videoLinkModal");
    },
     /*  destination image popup */
    openImgPopup() {
      this.$modal.show("destinationImgModal");
    },
    closeImgPopup() {
      this.$modal.hide("destinationImgModal");
    },
    
  }
};
</script>
<style lang="scss" scoped>
  .destination-create-modal {
    .destination-modal-content {
      padding: 40px;
      font-size: 16px;
      h2 {
        margin-bottom: 20px;
        text-align: center;
      }
      input[type='radio'] {
        transform: scale(1.3);
      }
      .input-exp {
        font-size: 13px;
        margin: 0;
      }
    }
  }
  .destination-desc-modal {
    .destination-modal-content {
      background-color: #F5F5F5;
    }
  }
  .ltr-type {
    .destination-modal-content {
      text-align: left;
    }
    .sub-form-label {
      padding-left: 40px;
    }
  }
</style>
<style scoped lang="scss">
  .destination {
    width: unset;
    height: 100%;
    text-align: center;
    color: #002036;
  }

  .des-title {
    font-size: 30px;
    margin-top: 10px;
  }

  .airport-content {
    width: 280px;
    
    .airport-title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 35px;
        height: 35px;
        border-radius: 5px;
        margin-left: 10px;
      }
      p {
        margin: 0;
        font-size: 15px;
        font-weight: bold;
      }
    }
    .each-airport {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 5px;
      p {
        margin: 0;
        text-align: initial;
      }
      .enter-symbol {
        width: 10px;
        height: 10px;
      }
      .plane-sm {
        width: 20px;
        height: 20px;
        margin: 0 4px;
      }
      .airport-name {
        font-size: 14px;
        font-weight: bold;
        line-height: 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 145px;
      }
      .airport-des{
        font-size: 10px;
        color: #707070;
      }
      .airport-code {
        margin: 0 5px;
      }
    }
    .input{
      height: 20px;
      font-size: 13px;
      padding: 0 5px;
    }
    .input_name{
      width: 100px;
    }
    .input_code{
      width: 40px;
      text-transform:uppercase;
    }

    .add-form-btn {
      color: #E22929;
      // margin-right: 50px;
      text-decoration: underline;
    }

    .add-form {
      display: none;
      background-color: lightgray;
      margin-top: 5px;
    }


  }

  .search-tag{
    font-size: 14px;
    height: 33px;
  }

  .tag-opt{
    height: 35px;
  }

  .total-save-btn {
    color: white;
    margin-top: 50px;
    max-width: 530px;
  }
  .td-input {
    display: flex;
    align-items: center;
    margin: 3px 0;
    font-size: 14px;
    label {
      margin-right: 5px;
      font-weight: bold;
    }
    input {
      height: 30px;
      padding: 0 5px;
      font-size: 14px;
    }
  }
  .row-btn {
    font-size: 18px !important;
    padding: 0 5px;
  }
  .new-btn {
    color: #fff;
    background: #e2b629;
    margin: 10px 50px;
    border-radius: 4px;
  }
  .edit-des_btn {
    width: 25px;
    height: 25px;
    color: #fff;
    background-color: #0D3856;  
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 50%;
    &:hover {
      background-color: #0071BC;
    }
  }
  .add-video_btn {
    width: 140px;
  }
</style>